<script>
import api from "@/api";

export default {
  name: "",
  data() {
    return {};
  },
  methods: {
    getSettings() {
      var url = "/Settings/";
      api.Manager.axios.get(url);
    },
  },
};
</script>
<template>
  <div id="Settings" class="container-fluid">Settings</div>
</template>
